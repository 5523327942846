// breakpoints
$mobile-width: 768px;
$tablet-width: 769px;
$desktop-width: 1200px;

@mixin belowWidth($width) {
  @media (max-width: #{$width})  {
    @content;
  }
}

@mixin overWidth($width) {
  @media (min-width: #{$width})  {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width})  {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width})  {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width})  {
    @content;
  }
}